#mapManagePageV1{
  width: 100vw;
  height: 100vh;

}
// 页面标体样式
#mapManagePageV1 .head_title_type{
  width: 100%;
  height: 4vh;
  padding: 1.7% 0 0 10%;
  background: linear-gradient(to bottom, rgba(255,255,255,0.05) 0%, rgba(255,255,255,0.2) 100%);
  border-bottom: 0.05vw #b4b4b5 solid;
}

// 页面标题栏-内部样式
#mapManagePageV1 .top_css_div_1{
  transition: 0.2s;           // 动画
  width: 100vw;
  height: 7vh;
  background-image: url(./img/顶部.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  100% !important;
  position: absolute;
  z-index: 99;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.03); // 底阴
}
// 页面标题栏-样式-收缩样式
#mapManagePageV1 .top_css_div_1_shrink{
  //display: none;
  transition: 0.2s;           // 动画
  //left: 40%;
  width: 100vw;
  height: 0.1vh;
  background-color: #ccc;
  position: absolute;
  z-index: 99;
}

// 悬浮框样式-1-外框样式
#mapManagePageV1 .suspension_div_1_1{
  z-index: 99;
  top: 20vh;
  left: 2vw;
  position: absolute;
  width: 6vw;
  height: 5vh;
  //text-align: center;
  color:#fff;
  border-radius: 5px;         // 圆角
  box-shadow: 0 0 10px #000; /* 水平偏移|垂直偏移|模糊半径|颜色 */
}

// 悬浮框样式-2-外框样式
#mapManagePageV1 .suspension_div_2_1{

  cursor:pointer;
  z-index: 99;
  top: 28vh;
  left: 2vw;;
  position: absolute;
  width: 6vw;
  height: 5vh;
  //text-align: center;
  color:#fff;
  border-radius: 5px;         // 圆角
  box-shadow: 0 0 10px #000; /* 水平偏移|垂直偏移|模糊半径|颜色 */
}
// 悬浮框样式-3-外框样式
#mapManagePageV1 .suspension_div_3_1{
  cursor:pointer;
  z-index: 99;
  top: 8vh;
  left: 2vw;;
  position: absolute;
  width: 25vw;
  height: 80vh;
  color:#fff;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 5px;         // 圆角
  box-shadow: 0 0 10px #000; /* 水平偏移|垂直偏移|模糊半径|颜色 */
}
// 悬浮框样式-3-按钮样式
#mapManagePageV1 .suspension_div_3_bottom{
  width: 100%;
  height: 5%;
  border-radius: 3px;
  padding: 2%;
  color:#fff;
  background: linear-gradient(to bottom, rgba(111,111,111,1) 0%, rgba(0,0,0,1) 100%);
  box-shadow: inset 0 0 10px rgba(233, 233, 233, 0.3);
  border: 2px rgba(23, 24, 25, 0) solid;
}
#mapManagePageV1 .suspension_div_3_bottom:hover{
  color: #fa8c16;
  background: linear-gradient(to bottom, rgb(37, 37, 37) 0%, rgba(0,0,0,1) 100%);
  //border: 5px rgba(0, 0, 0, 1) solid;  // 边框
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 1); /* 水平偏移|垂直偏移|模糊半径|颜色 */
}
// 悬浮框样式-通用-隐藏
#mapManagePageV1 .suspension_div_display{
  display: none;
  transition: 0.2s;           // 动画
  z-index: 99;
  position: absolute;
}
// 悬浮按钮样式-通用-内框样式
#mapManagePageV1 .suspension_div_inside{
  cursor:pointer;
  z-index: 99;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(111,111,111,1) 0%, rgba(0,0,0,1) 100%);
  //text-align: center;
  color:#fff;
  padding: 3%;
  border-radius: 7px;         // 圆角
  border: 2px rgba(23, 24, 25, 0) solid;  // 边框
  box-shadow: inset 0 0 10px rgba(233, 233, 233, 0.3); /* 水平偏移|垂直偏移|模糊半径|颜色 */
}
// 悬浮按钮样式-通用-点击后样式
#mapManagePageV1 .suspension_div_onClick_1{
  cursor:pointer;
  z-index: 99;
  //position: absolute;
  width: 100%;
  height: 5%;
  background-color: #faa930;
  //background: linear-gradient(to bottom, rgb(255, 166, 39) 0%, rgb(255, 225, 163) 100%);
  color: #fff;
  padding: 3%;
  border-radius: 3px;         // 圆角
  border: 1px rgb(255, 131, 0) solid;  // 边框
  box-shadow: inset 0 0 10px rgb(209, 67, 0); /* 水平偏移|垂直偏移|模糊半径|颜色 */
}
// 悬浮按钮样式-通用-点击后样式
#mapManagePageV1 .suspension_div_onClick_2{
  cursor:pointer;
  z-index: 99;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba( 0,0,0,1);
  //text-align: center;
  color:#fff;
  padding: 3%;
  border-radius: 7px;         // 圆角
  //border: 1px rgba(23, 24, 25, 0) solid;  // 边框
  //box-shadow: inset 0 0 10px rgba(233, 233, 233, 0.3); /* 水平偏移|垂直偏移|模糊半径|颜色 */
}
// 悬浮按钮样式-1-内框鼠标悬浮按钮
#mapManagePageV1 .suspension_div_inside:hover{
  color: #fa8c16;
  background: linear-gradient(to bottom, rgb(37, 37, 37) 0%, rgba(0,0,0,1) 100%);
  //border: 5px rgba(0, 0, 0, 1) solid;  // 边框
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 1); /* 水平偏移|垂直偏移|模糊半径|颜色 */
}
// 悬浮按钮样式-调压柜图片
#mapManagePageV1 .suspension_img_1{
  width: 35%;
  height: 85%;
  float: left;
  background-image: url(./img/调压柜.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100% !important;
}
// 悬浮按钮样式-阀门井图片
#mapManagePageV1 .suspension_img_2{
  width: 35%;
  height: 85%;
  float: left;
  background-image: url(./img/阀井.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100% !important;
}


// 底部第一个
#mapManagePageV1 .bottom_css_div_1{
  transition: 0.2s;           // 动画
  z-index: 99;
  top: 72vh;
  left: 0.5vw;;
  position: absolute;
  width: 25.5vw;
  height: 25vh;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.03); // 底阴
}
// 底部第一个-回缩样式
#mapManagePageV1 .bottom_css_div_1_shrink{
  transition: 0.2s;           // 动画
  z-index: 99;
  top: 99.9vh;
  left: 0.5vw;;
  position: absolute;
  width: 25.5vw;
  height: 0.1vh;
  background-color: #ccc;
}
// 底部第二个
#mapManagePageV1 .bottom_css_div_2{
  transition: 0.2s;           // 动画
  z-index: 99;
  top: 72vh;
  //left: 485px;
  left: 26.5vw;
  position: absolute;
  width: 25.5vw;
  height: 25vh;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.03); // 底阴
}
// 底部第二个-回缩样式
#mapManagePageV1 .bottom_css_div_2_shrink{
  transition: 0.2s;           // 动画
  z-index: 99;
  top: 99.9vh;
  left: 26.5vw;
  position: absolute;
  width: 25.5vw;
  height: 0.1vh;
  background-color: #ccc;
}
// 底部第三个
#mapManagePageV1 .bottom_css_div_3{
  transition: 0.2s;           // 动画
  z-index: 99;
  top: 72vh;
  left: 52.5vw;
  position: absolute;
  width: 25.5vw;
  height: 25vh;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.03); // 底阴
}
// 底部第三个-回缩样式
#mapManagePageV1 .bottom_css_div_3_shrink{
  transition: 0.2s;           // 动画
  z-index: 99;
  top: 99.9vh;
  left: 52.5vw;
  position: absolute;
  width: 25.5vw;
  height: 0.1vh;
  background-color: #ccc;
}
// 底部第四个
#mapManagePageV1 .bottom_css_div_4{
  z-index: 99;
  top: 10vh;
  left: 79vw;
  position: absolute;
  width: 20vw;
  height: 87vh;
  //background-color: #242323;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.03); // 底阴
}
// 底部第四个-回缩样式
#mapManagePageV1 .bottom_css_div_4_shrink{
  transition: 0.2s;           // 动画
  z-index: 99;
  top: 99.9vh;
  left: 79vw;
  position: absolute;
  width: 20vw;
  height: 0.1vh;
  background-color: #ccc;
}
// 字体样式-1
#mapManagePageV1  .span_css_type_1{
  font-size: 1.7vw;
  font-weight: 1000;
  color: #f1edf0; /* 设定文字颜色 */
  text-shadow:
          0 0 10px rgba(218, 146, 75, 0.5), /* 第一层外发光 */
          0 0 20px rgba(218, 146, 75, 0.7), /* 第二层外发光 */
          0 0 30px rgba(218, 146, 75, 0.8), /* 第三层外发光 */
          0 0 40px rgba(218, 146, 75, 0.9); /* 第四层外发光 */
}
// 字体样式-2
#mapManagePageV1  .span_css_type_2{
  color: #fff;
  font-size: 0.75vw;
  font-weight: 850;
  float:left
}
// 字体样式-3
#mapManagePageV1  .span_css_type_3{
  color: #fff;
  font-size: 0.8vw;
  font-weight: 850;
}
// 字体样式-4
#mapManagePageV1  .span_css_type_4{
  letter-spacing: 2px;  // 字体间距
  font-size: 1.6vw;
  font-weight: 1000;
  color: #f1edf0; /* 设定文字颜色 */
}
// 字体样式-5
#mapManagePageV1  .span_css_type_5{
  letter-spacing: 2px;  // 字体间距
  font-size: 1vw;
  font-weight: 1000;
  color: #f1edf0; /* 设定文字颜色 */
  :hover{
    color: #fa8c16;
  }
}
// 字体样式-6
#mapManagePageV1  .span_css_type_6{
  font-weight: bold;
  float:left;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
//进度条
#mapManagePageV1  .strip_css_type_1{
  width:95%;
  height:100%;
  border-radius: 15px;
  background: linear-gradient(to right, rgba(240,150,36,0.9),#fbf1de);
}
//天气变化 -多云
#mapManagePageV1 .head_weather_img_1{
  width: 100%;
  height: 100%;
  background-image: url(./img/多云@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto !important;
}
//天气变化 -晴
#mapManagePageV1 .head_weather_img_2{
  width: 100%;
  height: 100%;
  background-image: url(./img/晴@2x.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto !important;

}
//天气变化 -雨天
#mapManagePageV1 .head_weather_img_3{
  width: 100%;
  height: 100%;
  background-image: url(./img/雨天.png) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto !important;
}
#mapManagePageV1 .GlobalOutlined{
  font-size: 15px;
  color:#fff;
  :hover{
    color:rgba(240,150,36,0.9)
  }
}
#mapManagePageV1 .GlobalOutlined_type_1{
  font-size: 15px;
  color:red;
  :hover{
    color:rgba(240,150,36,0.9)
  }
}
// 按钮样式-1
#mapManagePageV1 .button_type_css_1{
  padding-top: 1vh;
  text-align: center;
  cursor:pointer;
  color: #fff;
  width:85%;
  margin-top:5%;
  height:4.5vh;
  background-image: url(./img/按钮样式-1.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  100% !important;
}
// 按钮样式-1-悬浮效果
#mapManagePageV1 .button_type_css_1:hover{
  color: #fff;
  font-weight: 300;
  background-image: url(./img/按钮样式-3.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  100% !important;
}
// 按钮样式-2
#mapManagePageV1 .button_type_css_2{
  color: #000;
  font-weight: 900;
  padding-top: 1vh;
  text-align: center;
  cursor:pointer;
  width:85%;
  margin-top:5%;
  height:4.5vh;
  background-image: url(./img/按钮样式-2.png) !important;
  background-repeat: no-repeat;
  background-size: 100%  100% !important;
}
